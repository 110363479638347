<!-- 商品统计 -->

<template>
  <div class="productStatistics">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <!-- <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelect" clearable>
        </el-date-picker> -->
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="图片" width="110">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.picUrl)">预览图片</span>
            </template>
          </el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="brand" label="品牌" width="220"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="productName" label="商品名称"
            width="220"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="barCode" label="商品条形码"
            width="220"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="factoryName" label="厂家"
            width="220"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="purchaseAmount" label="送货数量"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="sellAmount" label="销售数量"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="inventoryAmount" label="库存数量"></el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <el-image-viewer v-if="showViewer" :on-close="() => { showViewer = false }" :url-list="imgList"></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      loading: false,
      productValue: '', // 搜索商品名称
      // dataValue: '', // 搜索时间区间
      barCode: '',
      // 表格数据
      tableData: [],
      // 图片预览
      showViewer: false,
      imgList: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getProductStatistics();
  },
  methods: {
    // 获取商品列表
    getProductStatistics() {
      this.loading = true;
      let params = {
        productName: this.productValue || null, // 搜索商品名称
        barCode: this.barCode || null,
        page: this.currentPage,
        pageSize: this.pageSize,
      }
      // 时间区间
      // if (this.dataValue && this.dataValue.length === 2) {
      //   params.startTime = this.dataValue[0];
      //   params.endTime = this.dataValue[1];
      // } else {
      //   params.startTime = null;
      //   params.endTime = null;
      // }
      this.$axios.get(this.$api.getProductStatistics, { params }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getProductStatistics();
    },
    // 时间搜索
    // handleSelect(value) {
    //   this.clearData();
    //   this.dataValue = value;
    //   this.getProductStatistics();
    // },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getProductStatistics();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getProductStatistics();
    },
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
  }

}



</script>

<style scoped lang="scss">
.productStatistics {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }

  .show_img {
    color: #069BBC;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

}
</style>
